import './style.scss';


document.addEventListener('DOMContentLoaded', function() {
    // Sélectionner tous les inputs radio pour les contrats et villes
    const dropdownRadios = document.querySelectorAll('.dropdown-wrapper input,.dropdown-wrapper input');

    // Fonction pour mettre à jour la valeur sélectionnée
   function updateSelectedValue(radio) {
	    // Récupérer le "name" de l'input radio (par exemple 'contract' ou 'city')
	    const radioName = radio.name;

	    // Récupérer la "value" de l'input radio sélectionné
	    const selectedValue = radio.getAttribute('data-label');
	    // Trouver le parent de la classe "dropdown-inline"
	    const dropdownInline = radio.closest('.dropdown-wrapper');

	    if (dropdownInline) {
	        // Chercher l'élément qui a la classe "selected-value" à l'intérieur du parent
	        const selectedValueDiv = dropdownInline.querySelector('.selected-value');
	        
	        // Mettre à jour le texte du "selected-value" avec la "value" récupérée
	        if (selectedValueDiv) {
	            selectedValueDiv.textContent = selectedValue;
	        }
	    }
	}

    // Mettre à jour la valeur sélectionnée lorsqu'un contrat ou une ville est choisi
    dropdownRadios.forEach(radio => {
        radio.addEventListener('change', function() {
            updateSelectedValue(this);
        });
    });
});
